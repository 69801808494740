import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    console.error(info, props, err)
    // Customize the root error boundary for your micro frontend here.
    return <p>Ops... Looks like something failed here!</p>
  },
})

export const { bootstrap, mount, unmount } = lifecycles
