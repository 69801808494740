import { Box, Tab, Tabs } from '@mui/material'
import styled from '@emotion/styled'
import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import kebabCase from 'lodash.kebabcase'

const MOBILE_NAVBAR_HEIGHT = 72
const DESKTOP_NAVBAR_HEIGHT = 88
export interface TabItemDto {
  label: string
  value: string
}

export interface HomeTabsProps {
  tabs: TabItemDto[]
  activeTab: TabItemDto | null
  onChange: (tab: TabItemDto) => void
}

const StyledTabs = styled(Tabs)`
  letter-spacing: 2px;
  font-size: 12px;

  a {
    padding: 4px 0;
    font-weight: 300;
    min-height: 34px;
  }

  span {
    //Hiddens default border to match design - correct border is added through theme
    display: none;
  }
`

export const HomeTabs = ({ activeTab, tabs, onChange }: HomeTabsProps): JSX.Element => {
  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'transparent',
        position: 'sticky',
        zIndex: 5,
        background: 'white',
        mt: { xs: 2, md: 0 },
        top: { xs: MOBILE_NAVBAR_HEIGHT, md: DESKTOP_NAVBAR_HEIGHT },
      }}
    >
      <StyledTabs
        value={activeTab?.value ?? undefined}
        onChange={(_e, v: string) => {
          onChange(tabs.find(({ value }) => v === value) ?? null)
        }}
        aria-label='home tabs'
      >
        {tabs.map(({ label, value }, key) => (
          <Tab
            component='a'
            label={`${label}`}
            value={value}
            key={key}
            sx={{ marginRight: { xs: 4, md: 8 } }}
            {...generateDataAttrs({
              metaName: `${kebabCase(label)}-tab`,
              metaAction: `click-on-${kebabCase(label)}-tab`,
            })}
          />
        ))}
      </StyledTabs>
    </Box>
  )
}
