import { AuthProvider, FeatureFlagsProvider, UserDataProvider } from '@avenue-8/platform-shared-util-frontend'
import { theme } from '@avenue-8/platform-style-util-frontend'
import { ThemeProvider } from '@mui/material'
import { HomeModule } from './modules/home/home.module'
import { CheckAuth } from './modules/shared/components/check-auth'
import { services, ServicesContext } from './modules/shared/contexts/services.context'

export const PamModule = (): JSX.Element => {
  return (
    <AuthProvider>
      <CheckAuth />
      <ThemeProvider theme={theme}>
        <FeatureFlagsProvider>
          <ServicesContext.Provider value={services}>
            <UserDataProvider>
              <HomeModule />
            </UserDataProvider>
          </ServicesContext.Provider>
        </FeatureFlagsProvider>
      </ThemeProvider>
    </AuthProvider>
  )
}
