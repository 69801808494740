import { FeatureFlagsProvider } from '@avenue-8/platform-shared-util-frontend'
import { MediaMatch } from '@avenue-8/platform-style-util-frontend'
import { Redirect, Route, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundaryProvider } from '../shared/components/error-boundary-provider'
import { AssetTypesProvider } from '../shared/contexts/asset-types.context'
import { DHContextProvider } from '../shared/contexts/design-huddle.context'
import { ControlledHistoryHomeTabs } from './components/home-tabs/controlled-history-home-tabs'
import { HistoryHomeTabs } from './components/home-tabs/history-home-tabs'
import { MainLayout } from './components/main-layout/main-layout'
import { DiscoverPage } from './modules/discover/components/discover.page'
import { ReadyToGoContentPage } from './modules/ready-to-go-content/components/ready-to-go-content.page'

export const HomeModule = (): JSX.Element => {
  return (
    <ErrorBoundaryProvider>
      <BrowserRouter basename='/account/marketing'>
        <Switch>
          <AssetTypesProvider>
            <Route exact path='/'>
              <Redirect to={{ pathname: '/discover' }} />
            </Route>
            <Route path='/projects'>
              <MainLayout>
                <FeatureFlagsProvider>
                  <ControlledHistoryHomeTabs activeTabValue={'/projects'} />
                </FeatureFlagsProvider>
              </MainLayout>
            </Route>
            <Route path='/emails/gallery'>
              <MainLayout>
                <FeatureFlagsProvider>
                  <ControlledHistoryHomeTabs activeTabValue={'/discover'} />
                </FeatureFlagsProvider>
              </MainLayout>
            </Route>
            <Route path='/edit/gallery'>
              <MainLayout>
                <FeatureFlagsProvider>
                  <MediaMatch size='md' reference='up'>
                    <ControlledHistoryHomeTabs activeTabValue={'/discover'} />
                  </MediaMatch>
                </FeatureFlagsProvider>
              </MainLayout>
            </Route>
            <FeatureFlagsProvider>
              <DHContextProvider>
                <Route path='/discover'>
                  <MainLayout>
                    <HistoryHomeTabs />
                    <DiscoverPage />
                  </MainLayout>
                </Route>
                <Route path='/content'>
                  <MainLayout>
                    <ReadyToGoContentPage />
                  </MainLayout>
                </Route>
              </DHContextProvider>
            </FeatureFlagsProvider>
          </AssetTypesProvider>
        </Switch>
      </BrowserRouter>
    </ErrorBoundaryProvider>
  )
}
