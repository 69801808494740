import { useScreenSize } from '@avenue-8/platform-style-util-frontend'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

type HideByLocationWrapperType = {
  hiddenLocations: string[]
  children: JSX.Element
  smallScreenOnly?: boolean
}

export default function HideByLocationWrapper({
  hiddenLocations,
  children,
  smallScreenOnly = false,
}: HideByLocationWrapperType): JSX.Element {
  const { isSmallScreen } = useScreenSize()
  const location = useLocation()
  const [hideChildren, setHideChildren] = useState(true)

  useEffect(() => {
    const isThisLocationHidden = !!hiddenLocations.find((hiddenLocation) => {
      return location.pathname.includes(hiddenLocation)
    })

    const checkIfSmallScreenMatches = smallScreenOnly ? isSmallScreen : true

    setHideChildren(isThisLocationHidden && checkIfSmallScreenMatches)
  }, [location, isSmallScreen, hiddenLocations, smallScreenOnly])

  return <>{hideChildren ? <></> : <>{children}</>}</>
}
