import { SectionContainer, Tooltip } from '@avenue-8/platform-style-util-frontend'
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { AssetType } from '../../../../shared/domain/models/asset-type'
import AssetCard from '../components/asset-card'

type SelectAssetSectionType = {
  assets: AssetType[]
  assetTypesError: boolean
  assetTypesLoading: boolean
  onAssetCardClick: (asset: AssetType) => void
}

export default function SelectAssetSection({
  assets,
  assetTypesError,
  assetTypesLoading,
  onAssetCardClick,
}: SelectAssetSectionType): JSX.Element {
  return (
    <Box mt={4}>
      <SectionContainer
        title='Select an asset'
        tooltip={
          <Tooltip
            title={'Don’t see the asset you’re looking for?'}
            description='At this time, we are only supporting these assets
        on mobile devices. Login on desktop to edit and
        request our full suite of assets, including 
        postcards, emails and more!'
          />
        }
      >
        <Grid container columnSpacing={6} rowSpacing={3} data-testid='asset-cards-container'>
          {assetTypesError && (
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
              <Typography fontFamily='Canela' fontWeight={400} sx={{ fontSize: { xs: 16, sm: 20 } }}>
                We had a problem fetching assets, please try refreshing.
              </Typography>
            </Grid>
          )}
          {assetTypesLoading && (
            <Grid mt={3} width='100%'>
              <Skeleton variant='rectangular' width={'100%'} height={350} />
            </Grid>
          )}

          {!assetTypesError &&
            assets?.length > 0 &&
            assets?.map((asset: AssetType) => {
              return (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={asset.identifier}>
                  <AssetCard
                    thumbnail={asset.thumbnail}
                    identifier={asset.identifier}
                    title={asset.title}
                    description={asset.description}
                    enabled={asset.enabled}
                    onClick={() => onAssetCardClick(asset)}
                  />
                </Grid>
              )
            })}
        </Grid>
      </SectionContainer>
    </Box>
  )
}
