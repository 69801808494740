import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import { ContentCard } from '@avenue-8/platform-style-util-frontend'
import { RtgExportModal } from '../../../../shared/components/modal/rtg-export-modal'
import { DHTemplateType } from '../../../../shared/domain/models/design-huddle'
import kebabCase from 'lodash.kebabcase'

type ReadyToGoGalleryType = {
  contents: DHTemplateType[]
  RTGId: number
  maxItemsToShow?: number
}

const GalleryGrid = styled(Grid)`
  margin-bottom: 32px;
`

export default function ReadyToGoGallery({
  contents = [],
  maxItemsToShow = null,
  RTGId,
}: ReadyToGoGalleryType): JSX.Element {
  const history = useHistory()
  const [template, setTemplate] = useState<DHTemplateType | null>(null)

  const displayedContents = (maxItemsToShow ? contents.slice(0, maxItemsToShow) : contents) || []

  const handleDownloadClick = async (id) => {
    // add the export modal summoning here
    // history.push(`/edit/project/new/${id}/${RTGId}`)
    setTemplate(contents?.find((template) => template.templateId === id) ?? null)
  }

  const handleProjectModalClose = () => {
    setTemplate(null)
  }

  const handleCustomize = (id: number) => {
    history.push(`/edit/project/new/${id}/${RTGId}`)
  }

  return (
    <Grid container>
      {template && (
        <RtgExportModal
          open={!!template}
          onClose={handleProjectModalClose}
          template={template}
          onDownload={handleProjectModalClose}
          onSocialShareClick={handleProjectModalClose}
          onCustomizeClick={handleCustomize}
          showSocialMediaShareMessage='Share using Avenue 8 mobile app'
        />
      )}
      {displayedContents.length > 0 ? (
        displayedContents?.map((content: DHTemplateType) => (
          <GalleryGrid key={content.templateId} item xs={6} sm={6} md={4} lg={3} xl={2}>
            <ContentCard
              id={content.templateId}
              src={content.previewUrl}
              title={content.templateTitle}
              category={content.categoryItem.name}
              description={content.description}
              onClick={handleDownloadClick}
              {...generateDataAttrs({
                metaName: `${kebabCase(content.templateTitle)}-ready-to-go-content`,
                metaAction: `click-on-${kebabCase(content.templateTitle)}-ready-to-go-content`,
              })}
            />
          </GalleryGrid>
        ))
      ) : (
        <Typography fontFamily='Canela' fontWeight={400} sx={{ fontSize: { xs: 16, sm: 20 } }}>
          Hmmm, seems like you don&apos;t have any templates for this category... Get in touch with us to add some!
        </Typography>
      )}
    </Grid>
  )
}
