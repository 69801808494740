import {
  WebviewBridgeHandlerName,
  useWebviewBridgeContext,
  ExportShareModal as ExportShareModalWrapper,
} from '@avenue-8/platform-shared-util-frontend'
import {
  useScreenSize,
  Handlers,
  ExportShareModal,
  DownloadFormat,
  SocialShareOption,
} from '@avenue-8/platform-style-util-frontend'
import kebabCase from 'lodash.kebabcase'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ServicesContext } from '../../contexts/services.context'
import { DHTemplateType } from '../../domain/models/design-huddle'
import { DHProject } from '../../services/design-huddle-proxy.service'
import { UrlDownload } from '../../utils/url-download'
import { ZipDownload } from '../../utils/zip-download'

export type RtgExportModalProps = {
  open: boolean
  onClose: () => void
  template: DHTemplateType
  onDownload?: () => void
  onSocialShareClick?: () => void
  onCustomizeClick?: (id: number) => void
  showSocialMediaShareMessage?: string
  caption?: string
}

export const RtgExportModal = (props: RtgExportModalProps): JSX.Element => {
  const { template } = props
  const { postMessage } = useWebviewBridgeContext()
  const { isDesktop } = useScreenSize()
  const { designHuddleProxy } = useContext(ServicesContext)
  const [previews, setPreviews] = useState<{ url: string; id: string }[]>([])
  const [status, setStatus] = useState<'IDLE' | 'LOADING' | 'ERROR'>('LOADING')
  const [caption, setCaption] = useState('')
  const handleClose = () => {
    props.onClose()
  }

  useEffect(() => {
    setPreviews(template?.previewUrl ? [{ url: template.previewUrl, id: template.templateId.toString() }] : [])
    setStatus('LOADING')
    designHuddleProxy
      .getTemplate(template.templateId)
      .then((response: DHTemplateType) => {
        setCaption(response.socialPostText)
      })
      .catch((error) => {
        alert('Failed to render caption')
        console.error(`Failed to render caption: ${error?.message || '[unknown reason]'}`, error)
      })
      .finally(() => {
        setStatus('IDLE')
      })
  }, [template, designHuddleProxy])

  const suggestedFileName = useMemo(
    () => (template ? kebabCase('avenue 8 ' + template.templateTitle) : undefined),
    [template]
  )

  const renderPages = useCallback(
    async (
      fileName: string,
      fileType: DownloadFormat
    ): Promise<void | { pages: { id: string; url: string }[]; project?: DHProject }> => {
      if (template) {
        setStatus('LOADING')
        const project = await designHuddleProxy
          .createProject(template.templateId)
          // Force waiting after the project creation to give time to DH backend to think...
          .then(
            (project: DHProject): Promise<DHProject> =>
              new Promise((resolve) => setTimeout(() => resolve(project), 5000))
          )
          .catch((error) => {
            setStatus('IDLE')
            throw error
          })

        return designHuddleProxy
          .renderProject(project.projectId, fileType)
          .then((renderedPages) => {
            return {
              pages: renderedPages
                .sort(({ pageNumber: a }, { pageNumber: b }) => a - b)
                .map((page) => ({
                  id: page.pageId,
                  url: page.url,
                })),
              project: {
                ...project,
                template: { ...template, ...project.template },
              },
            }
          })
          .catch((error) => {
            alert('Failed to render project')
            console.error(`Failed to render project: ${error?.message || '[unknown reason]'}`, error)
          })
          .finally(() => {
            setStatus('IDLE')
          })
      }
    },
    [designHuddleProxy, template]
  )

  const handleDownload = (fileName: string, fileType: DownloadFormat) => {
    renderPages(fileName, fileType).then((renderResult) => {
      if (!renderResult) return
      const { pages } = renderResult
      if (fileType === 'png') {
        setPreviews(pages)
      }
      const safeFileName = fileName.trim() || suggestedFileName
      if (postMessage) {
        postMessage(WebviewBridgeHandlerName.download, { url: pages.map((page) => page.url) })
      } else {
        if (pages.length > 1) {
          return ZipDownload(safeFileName, pages, fileType)
        } else {
          const [singlePage] = pages
          return UrlDownload(singlePage.url, `${safeFileName}.${fileType}`)
        }
      }
    })
  }

  const handleSocialShare = useCallback(
    (socialNetwork: SocialShareOption, _mediaArray: string[]) => {
      if (postMessage) {
        renderPages(suggestedFileName, 'png').then(async (renderResult) => {
          if (!renderResult) return
          const { pages } = renderResult
          const urlList = pages.map((page) => page.url)
          const payload = { url: urlList }
          switch (socialNetwork) {
            case 'facebook':
              postMessage(WebviewBridgeHandlerName.facebook, payload)
              break
            case 'instagram':
              postMessage(WebviewBridgeHandlerName.instagram, payload)
              break
          }
        })
      } else {
        alert(`Can't share to social media through desktop, try using the Avenue 8 app.`)
      }
    },
    [renderPages, suggestedFileName, postMessage]
  )

  const handlers = useMemo<Handlers[]>(() => {
    return Object.entries({
      preview: isDesktop,
      download: true,
      socialShare: postMessage && handleSocialShare,
      caption: true,
      customize: !!props.onCustomizeClick,
    })
      .filter(([_, handlerValue]) => !!handlerValue)
      .map(([handlerName]) => handlerName as Handlers)
  }, [isDesktop, postMessage, handleSocialShare, props.onCustomizeClick])

  return (
    <ExportShareModalWrapper
      PresentionalComponent={ExportShareModal}
      open={props.open}
      onClose={handleClose}
      title={'You‘re almost done! Please review your asset.'}
      subtitle='Please make sure everything looks right before downloading.'
      defaultValues={{
        fileName: suggestedFileName,
        caption,
      }}
      pagePreviews={previews}
      handlers={handlers}
      status={status}
      mediaType={template?.mediaType}
      onDownloadClick={handleDownload}
      onSocialShareClick={postMessage && handleSocialShare}
      onCustomizeClick={() => props.onCustomizeClick(template.templateId)}
      socialMediaShareMessage={props.showSocialMediaShareMessage}
    />
  )
}
