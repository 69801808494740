import { AppBar, Box, InputAdornment, OutlinedInput, Stack, Toolbar, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { ChangeEventHandler } from 'react'
import { UserAvatarDropDown, useScreenSize } from '@avenue-8/platform-style-util-frontend'
import { HistoryUserMenu } from '@avenue-8/platform-shared-util-frontend'

export type TopNavBarProps =
  | { showSearch?: false; shouldStickyNavbar?: boolean }
  | {
      showSearch: true
      onSearchInputChange: ChangeEventHandler<HTMLInputElement>
      searchInputValue: string
      shouldStickyNavbar?: boolean
    }

export const TopNavBar = (props: TopNavBarProps): JSX.Element => {
  const { isDesktop } = useScreenSize()
  return (
    <Box sx={{ flexGrow: 1, position: props.shouldStickyNavbar ? 'sticky' : 'relative', top: 0, zIndex: 5 }}>
      <AppBar
        color='secondary'
        position='static'
        sx={{
          padding: { xs: '8px 24px', md: '8px 0' },
          backgroundColor: { xs: `#fafaff !important`, md: 'white !important' },
        }}
      >
        <Toolbar disableGutters variant='regular'>
          <Box sx={{ flexShrink: 1 }}>
            <Typography fontFamily='Canela' fontWeight={400} sx={{ fontSize: { xs: 36, md: 48 } }}>
              Marketing
            </Typography>
          </Box>
          <Stack
            direction='row'
            spacing={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flexGrow: 1,
            }}
          >
            {props.showSearch && (
              <OutlinedInput
                id='outlined-templates-search-input'
                type='text'
                placeholder='Search for a template'
                value={props.searchInputValue}
                onChange={props.onSearchInputChange}
                endAdornment={
                  <InputAdornment position='end'>
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            )}
            <HistoryUserMenu UserMenuComponent={UserAvatarDropDown} embed={!isDesktop} />
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
