import React from 'react'
import { Typography } from '@mui/material'
import styled from '@emotion/styled'
import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import kebabCase from 'lodash.kebabcase'

export type AssetCardType = {
  title: string
  description: string
  thumbnail: string
  enabled?: boolean
  identifier: string
  onClick?: (url: string) => void
}

type WrapperProps = {
  enabled?: boolean
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  width: 315px;
  border: 1px solid #d5d6ea;
  border-radius: 4px;
  padding: 4px 0 4px 10px;
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'not-allowed')};
  pointer-events: ${({ enabled }) => (enabled ? 'auto' : 'none')};
  opacity: ${({ enabled }) => (enabled ? '1' : '0.45')};

  &:hover {
    background: #fafaff;
  }
`

const InformationContainer = styled.div`
  max-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 20px;
  padding: 8px 0;
`
const ConstrainedCardImageBox = styled.div`
  display: flex;
  align-items: center;
  height: 87px;
  width: 87px;
`
const ConstrainedCardImage = styled.img`
  height: 77px;
  width: 77px;
`

const Title = styled(Typography)``
const Subtitle = styled(Typography)``

const ComingSoonLabel = styled.div`
  position: absolute;
  text-transform: uppercase;
  padding: 4px 16px;
  background: #f2f2fd;
  border-radius: 4px;
  top: 1px;
  right: 1px;
  font-size: 12px;
`

export default function AssetCard({
  title,
  description,
  enabled = true,
  thumbnail,
  identifier,
  onClick,
}: AssetCardType): JSX.Element {
  return (
    <Wrapper
      onClick={() => onClick(identifier)}
      enabled={enabled}
      {...generateDataAttrs({
        metaName: `${kebabCase(title)}-asset`,
        metaAction: `click-on-${kebabCase(title)}-asset`,
      })}
    >
      {!enabled && (
        <ComingSoonLabel>
          <Typography variant='body2' fontWeight='400'>
            Coming soon!
          </Typography>
        </ComingSoonLabel>
      )}
      <ConstrainedCardImageBox>
        <ConstrainedCardImage src={thumbnail} alt={title} role='img' aria-label={title} />
      </ConstrainedCardImageBox>
      <InformationContainer>
        <Title variant='body1' fontWeight='400'>
          {title}
        </Title>
        <Subtitle variant='body2' fontSize={13} color='textSecondary' fontWeight='400' lineHeight={1}>
          {description}
        </Subtitle>
      </InformationContainer>
    </Wrapper>
  )
}
