const triggerDownload = async (data: Blob, fileName: string): Promise<void> => {
  const url = window.URL.createObjectURL(data)
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export { triggerDownload }
