import { useFeatureFlags } from '@avenue-8/platform-shared-util-frontend'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { HomeTabs, TabItemDto } from './home-tabs'

export const ControlledHistoryHomeTabs = ({ activeTabValue }: { activeTabValue: string }): JSX.Element => {
  const history = useHistory()
  const { getFeatureData } = useFeatureFlags()
  const [tabs, setTabs] = useState<TabItemDto[]>([])
  const [activeTab, setActiveTab] = useState<TabItemDto | null>(null)
  const handleTabChange = (tab: TabItemDto) => {
    history.push(tab.value)
  }
  useEffect(() => {
    getFeatureData('pam2-landing-tabs').then((data) => {
      if (!data) {
        if (tabs.length) {
          setTabs([])
        }
        return
      }
      if (!Array.isArray(data)) {
        throw new Error('invalid landing tabs feature flag')
      }
      setTabs(data as TabItemDto[])
    })
  }, [tabs.length, getFeatureData])
  useEffect(() => {
    const selectedTabItem = tabs.find((tab) => tab.value === activeTabValue) ?? null
    setActiveTab(selectedTabItem)
  }, [activeTabValue, tabs])
  return activeTab ? <HomeTabs tabs={tabs} activeTab={activeTab} onChange={handleTabChange} /> : <></>
}
