import { AuthedFetch, CONTENT_GRID_SERVICE_URL } from '@avenue-8/platform-shared-util-frontend'
import { AssetType } from '../domain/models/asset-type'
import { DiscoverCarouselType, CarouselImageType } from '../domain/models/discover-carousel'
export interface IContentGridService {
  getAssetTypes: () => Promise<AssetType[]>
  getCarouselImages: (id) => Promise<CarouselImageType[]>
}

export function ContentGridServiceFactory(): IContentGridService {
  const getVisualMarketingTemplateCategories = async (): Promise<AssetType[]> => {
    const url = `${CONTENT_GRID_SERVICE_URL}/pam-marketing-asset-types`
    return AuthedFetch(url, {
      hideAgentId: true,
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Failed to fetch content grid categories')
      }
      return response.json()
    })
  }

  const getCarouselImagesImages = async (id): Promise<any> => {
    const url = `${CONTENT_GRID_SERVICE_URL}/carousels?id=${id}`
    return AuthedFetch(url, {
      hideAgentId: true,
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Failed to fetch discover carousel images')
      }
      return response.json()
    })
  }

  return {
    async getCarouselImages(id) {
      return getCarouselImagesImages(id)
        .then((data: DiscoverCarouselType) => {
          const orderedImages = data.images.sort((a, b) => a.order - b.order) || []
          return orderedImages
        })
        .catch((err) => {
          console.error('getCarouselImagesImages', err.message, err)
          throw err
        })
    },
    async getAssetTypes() {
      return getVisualMarketingTemplateCategories()
        .then((data: AssetType[]) => {
          return data
        })
        .catch((err) => {
          console.error('getVisualMarketingTemplateCategories', err.message, err)
          throw err
        })
    },
  }
}

export const ContentGridService = ContentGridServiceFactory()
