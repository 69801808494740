import { useFeatureFlags, useWebviewBridgeContext } from '@avenue-8/platform-shared-util-frontend'
import { Box } from '@mui/material'
import { MouseEventHandler, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ServicesContext } from '../../../../shared/contexts/services.context'
import { AssetTypesContext } from '../../../../shared/contexts/asset-types.context'
import { DHContext } from '../../../../shared/contexts/design-huddle.context'
import { CarouselImageType } from '../../../../shared/domain/models/discover-carousel'
import CarouselSection from '../sections/carousel-section'
import SelectAssetSection from '../sections/select-asset-section'
import ReadyToGoSection from '../sections/ready-togo-section'
import { AssetType } from '../../../../shared/domain/models/asset-type'

const mobileSectionsFlagKey = 'pam2-discover-mobile-sections'

export const DiscoverPage = (): JSX.Element => {
  const history = useHistory()
  const { postMessage } = useWebviewBridgeContext()
  const { getFeatureData } = useFeatureFlags()
  const [showCreateNewAssetSection, setShowCreateNewAssetSection] = useState<boolean>(false)
  const [carouselImages, setCarouselImages] = useState<CarouselImageType[]>([])
  const [carouselImagesError, setCarouselImagesError] = useState<boolean>(false)
  const carouselImagesList = useMemo(
    () => (postMessage ? carouselImages.map((image) => ({ ...image, redirectTo: '#' })) : carouselImages),
    [carouselImages, postMessage]
  )

  const { contentGrid } = useContext(ServicesContext)
  const { assetTypes, assetTypesError, assetTypesLoading } = useContext(AssetTypesContext)
  const { readyToGoContent, RTGLoading, RTGId } = useContext(DHContext)

  useEffect(() => {
    contentGrid
      .getCarouselImages('mainbanner')
      .then((images) => {
        setCarouselImages(images)
      })
      .catch(() => {
        setCarouselImagesError(true)
      })
  }, [contentGrid])

  useEffect(() => {
    if (postMessage) {
      getFeatureData(mobileSectionsFlagKey).then((flag) => {
        if (!flag) return
        if (typeof flag === 'string' || typeof flag === 'number' || typeof flag === 'boolean') {
          console.error(`invalid ${mobileSectionsFlagKey} flag data type ${typeof flag}`)
        } else {
          const sectionsConfig: Record<string, any> = flag ?? {}
          if (sectionsConfig?.createNewAsset) {
            setShowCreateNewAssetSection(!!sectionsConfig?.createNewAsset)
          }
        }
      })
    } else {
      setShowCreateNewAssetSection(true)
    }
  }, [getFeatureData, postMessage])

  const handleCarouselClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (postMessage) {
      // TODO: wait for iOS "open URL" message handler to be available
      event.preventDefault()
      event.stopPropagation()
      event.nativeEvent.preventDefault()
      event.nativeEvent.stopPropagation()
    }
  }

  const onAssetCardClick = ({ identifier, route }: AssetType) => {
    if (route) {
      history.push(route)
      return
    }

    if (identifier === 'emails') {
      history.push('emails/gallery')
    } else {
      history.push(`edit/gallery/${identifier}`)
    }
  }

  return (
    <Box>
      <CarouselSection
        onClick={handleCarouselClick}
        carouselImages={carouselImagesList}
        carouselImagesError={carouselImagesError}
      />
      {showCreateNewAssetSection && (
        <SelectAssetSection
          assets={assetTypes}
          onAssetCardClick={onAssetCardClick}
          assetTypesError={assetTypesError}
          assetTypesLoading={assetTypesLoading}
        />
      )}
      <ReadyToGoSection readyToGoContent={readyToGoContent} loading={RTGLoading} RTGId={RTGId} />
    </Box>
  )
}
