import { Link } from 'react-router-dom'
import { Skeleton, Typography, Box } from '@mui/material'
import ReadyToGoGallery from '../../ready-to-go-content/components/ready-to-go-gallery'
import { SectionContainer, useScreenSize, Tooltip } from '@avenue-8/platform-style-util-frontend'
import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'

const SeeAllButton = () => (
  <Box
    minWidth='75px'
    {...generateDataAttrs({
      metaAction: 'see-all-ready-to-go-content',
    })}
  >
    <Link to='/content' style={{ textDecoration: 'none' }}>
      <Typography textAlign='right' color='#2A2A2A' fontWeight={400} letterSpacing={2} fontSize={12}>
        SEE ALL
      </Typography>
    </Link>
  </Box>
)
type ReadyToGoSectionType = {
  readyToGoContent: any
  loading: boolean
  RTGId: number
}

export default function ReadyToGoSection({ readyToGoContent, loading, RTGId }: ReadyToGoSectionType): JSX.Element {
  const { isLargeDesktop, isDesktop, isTablet } = useScreenSize()
  const maxItemsPerBreakpoint = () => {
    if (isLargeDesktop) return 6
    if (isDesktop) return 4
    if (isTablet) return 2
    return 3
  }
  const maxItemsToShowInSelector = maxItemsPerBreakpoint()

  return (
    <Box mb={16} mt={5}>
      <SectionContainer
        title='Ready-to-go content'
        rightActionButton={<SeeAllButton />}
        tooltip={<Tooltip title={'Don’t see the asset you’re looking for?'} description='Get in touch with us!' />}
      >
        {loading ? (
          <Skeleton variant='rectangular' width='100%' height={125} />
        ) : (
          <ReadyToGoGallery contents={readyToGoContent} maxItemsToShow={maxItemsToShowInSelector} RTGId={RTGId} />
        )}
      </SectionContainer>
    </Box>
  )
}
