import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Skeleton } from '@mui/material'
import ReadyToGoGallery from './ready-to-go-gallery'
import { DHContext } from '../../../../shared/contexts/design-huddle.context'
import { Box } from '@mui/system'
import { InternalNavbar } from '@avenue-8/platform-style-util-frontend'

export const ReadyToGoContentPage = (): JSX.Element => {
  const history = useHistory()
  const { readyToGoContent, RTGLoading, RTGId } = useContext(DHContext)

  return (
    <Grid>
      <InternalNavbar
        title={'Ready-to-go content'}
        showBackButton={true}
        // rightButtonComponent={<FilterDialog />}
        onBackButtonClicked={() => history.goBack()}
      />
      <Box mt={3}>
        {RTGLoading ? (
          <Skeleton height='400px' width='100%' variant='rectangular' />
        ) : (
          <ReadyToGoGallery contents={readyToGoContent} RTGId={RTGId} />
        )}
      </Box>
    </Grid>
  )
}
