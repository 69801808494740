import { AuthedFetch, DH_PROXY_API_URL } from '@avenue-8/platform-shared-util-frontend'
import { DHTemplateEndpointReturn, DHTemplateType } from '../domain/models/design-huddle'

export type DHProject = {
  id: string
  title?: string
  template: { id: number; name: string } & Partial<DHTemplateType>
  projectId: string
} // it does have a lot more things, but we'll stick with only this for now

type CreateDigitalProjectRenderJobResponse = {
  msg: string
  jobId: number
  project: { id: string; pages: { pageId: string; pageNumber: number }[] }
}

function isCreateDigitalProjectRenderJobResponse(
  response: CreateDigitalProjectRenderJobResponse | CreateVideoProjectRenderJobResponse
): response is CreateDigitalProjectRenderJobResponse {
  return 'pages' in response.project && Array.isArray(response.project.pages) && response.project.pages.length > 0
}

type CreateVideoProjectRenderJobResponse = {
  msg: string
  jobId: number
  project: { id: string; scenes: { sceneId: string; sceneNumber: number; thumbnailUrl: string }[] }
}

function isCreateVideoProjectRenderJObResponse(
  response: CreateDigitalProjectRenderJobResponse | CreateVideoProjectRenderJobResponse
): response is CreateVideoProjectRenderJobResponse {
  return 'scenes' in response.project && Array.isArray(response.project.scenes) && response.project.scenes.length > 0
}

type ProjectJobResponse = { pages: { url: string; page: number }[]; status: 'complete' | 'incomplete' }

function convertToDigitalProject(
  response: CreateDigitalProjectRenderJobResponse | CreateVideoProjectRenderJobResponse
): CreateDigitalProjectRenderJobResponse {
  const digitalProject: CreateDigitalProjectRenderJobResponse = {
    msg: response.msg,
    jobId: response.jobId,
    project: { id: response.project.id, pages: [] },
  }
  if (isCreateVideoProjectRenderJObResponse(response)) {
    digitalProject.project.pages = response.project.scenes.map(({ sceneId: pageId, sceneNumber: pageNumber }) => ({
      pageId,
      pageNumber,
    }))
    return digitalProject
  } else if (isCreateDigitalProjectRenderJobResponse(response)) {
    return response
  } else {
    throw new Error('No pages found in server response')
  }
}

export type PaginationParams = {
  limit?: number
  page?: number
}

export type PaginatedResponse<T> = {
  data: T
  page?: number
  limit?: number
  total?: number
}

export interface IDesignHuddleProxyService {
  createProject: (templateId: number) => Promise<DHProject>
  getAccessToken: (accessType?: string) => Promise<any>
  getProjects: (params?: PaginationParams) => Promise<PaginatedResponse<DHProject[]>>
  getReadyToGoContent: (categoryId: number, accessType?: string) => Promise<DHTemplateType[]>
  getTemplate: (templateId: number) => Promise<DHTemplateType>
  renderProject: (
    projectId: string,
    type: 'png' | 'pdf' | 'mp4',
    accessLevel?: 'admin' | 'user'
  ) => Promise<{ pageId: string; pageNumber: number; url: string }[]>
}

export function DesignHuddleProxyServiceFactory(): IDesignHuddleProxyService {
  async function createProject(templateId: number) {
    return AuthedFetch(`${DH_PROXY_API_URL}/design/projects`, {
      hideAgentId: true,
      method: 'POST',
      headers: [['Content-Type', 'application/json']],
      body: JSON.stringify({ templateId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed creating project from template ${templateId}`)
        }
        return response.json()
      })
      .then((data: DHProject) => data)
  }

  const getReadyToGoContentTemplates = async (categoryId, acessType = 'user'): Promise<any> => {
    const url = `${DH_PROXY_API_URL}/design/templates?accessType=${acessType}&categoryId=${categoryId}`
    return AuthedFetch(url, {
      hideAgentId: true,
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Failed to fetch ready to go content')
      }
      return response.json()
    })
  }

  const getTemplateById = async (templateId): Promise<any> => {
    const url = `${DH_PROXY_API_URL}/design/templates/${templateId}`
    return AuthedFetch(url, {
      hideAgentId: true,
    }).then((response) => {
      if (!response.ok) {
        throw new Error(`Failed to fetch template: ${templateId}`)
      }
      return response.json()
    })
  }

  const getDHAccessToken = async (accessType = 'user'): Promise<{ token: string }> => {
    const url = `${DH_PROXY_API_URL}/design/access-token?${new URLSearchParams({ accessType })}`
    return AuthedFetch(url, {
      hideAgentId: true,
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Failed to fetch access token')
      }
      return response.json()
    })
  }

  async function getProjects({
    limit = 20,
    page = 1,
  }: {
    limit?: number
    page?: number
  }): Promise<PaginatedResponse<DHProject[]>> {
    const params = new URLSearchParams({ limit: Math.abs(limit).toFixed(0), page: Math.abs(page).toFixed(0) })
    const url = `${DH_PROXY_API_URL}/design/projects?${params}`
    return AuthedFetch(url, { hideAgentId: true })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then((body: PaginatedResponse<DHProject[]>) => body)
  }

  async function renderProject(
    projectId: string,
    format = 'png'
  ): Promise<{ pageId: string; pageNumber: number; url: string }[]> {
    const url = `${DH_PROXY_API_URL}/downloads/projects`
    const response = await AuthedFetch(url, {
      method: 'POST',
      headers: [['Content-Type', 'application/json']],
      body: JSON.stringify({
        projectId,
        format,
      }),
      hideAgentId: true,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to create export job')
        }
        return response.json()
      })
      .then((response: CreateDigitalProjectRenderJobResponse | CreateVideoProjectRenderJobResponse) => {
        return convertToDigitalProject(response)
      })
    let job: ProjectJobResponse
    do {
      job = await AuthedFetch(`${DH_PROXY_API_URL}/downloads/${response.jobId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText)
          }
          return response.json()
        })
        .catch((error) => {
          console.error(`Failed to fetch export job ${response.jobId} status: ${error.message}`)
          throw error
        })
    } while (job.status !== 'complete')
    const renderedPages = response.project.pages.map(({ pageId, pageNumber }) => {
      const page = job.pages.find(({ page }) => page === pageNumber)
      if (!page) {
        throw new Error(`Page #${pageNumber} (${pageId}) of ${projectId} in job ${response.jobId} was not rendered`)
      }
      return {
        pageId,
        pageNumber,
        url: page.url,
      }
    })
    return renderedPages
  }

  return {
    createProject,
    getProjects,
    renderProject,
    async getAccessToken() {
      return getDHAccessToken()
        .then((data: { token: string }) => {
          return data.token
        })
        .catch((err) => {
          console.error('getReadyToGoContent', err.message, err)
          throw err
        })
    },
    async getReadyToGoContent(categoryId, accessType) {
      return getReadyToGoContentTemplates(categoryId, accessType)
        .then((data: DHTemplateEndpointReturn) => data.data)
        .catch((err) => {
          console.error('getReadyToGoContent', err.message, err)
          throw err
        })
    },
    async getTemplate(templateId) {
      return getTemplateById(templateId)
        .then((data: DHTemplateType) => data)
        .catch((err) => {
          console.error('getTemplate', err.message, err)
          throw err
        })
    },
  } as IDesignHuddleProxyService
}

export const DesignHuddleProxyService = DesignHuddleProxyServiceFactory()
