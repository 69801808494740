import { AuthedFetch } from '@avenue-8/platform-shared-util-frontend'

const DESIGN_HUDDLE_SERVICE_URL = 'https://design.avenue8.com/editor/api' // TODO: move to .env

type CreateExportJobTypeReturn = {
  success: boolean
  data: {
    jobId: string
  }
}

type CreateExportJobParams = {
  projectId: string
  format?: 'pdf' | 'jpg' | 'png'
}

export interface IDesignHuddleService {
  createExportJobDH: ({ projectId }: CreateExportJobParams) => Promise<CreateExportJobTypeReturn>
}

export function DesignHuddleServiceFactory(): IDesignHuddleService {
  const createExportJob = async ({ projectId, format = 'pdf' }: CreateExportJobParams): Promise<any> => {
    const url = `${DESIGN_HUDDLE_SERVICE_URL}/projects/${projectId}/export`
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ format }),
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Failed to create export job')
      }
      return response.json()
    })
  }

  return {
    async createExportJobDH(projectId) {
      return createExportJob(projectId)
        .then((data) => {
          return data
        })
        .catch((err) => {
          console.error('getReadyToGoContent', err.message, err)
          throw err
        })
    },
  }
}

export const DesignHuddleService = DesignHuddleServiceFactory()
