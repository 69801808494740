import { WebviewBridgeProvider } from '@avenue-8/platform-shared-util-frontend'
import { PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import { TopNavBar } from '../top-nav-bar/top-nav-bar'
import HideByLocationWrapper from '../../../shared/components/hide-by-location-wrapper'
import { useLocation } from 'react-router'

const TOP_NAVBAR_HIDDEN_LOCATIONS = ['/edit/gallery']
const TOP_NAVBAR_RELATIVE_LOCATIONS = ['/discover', '/content']

// eslint-disable-next-line @typescript-eslint/ban-types
export const MainLayout = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const location = useLocation()
  const shouldRelativePositionLayout = !!TOP_NAVBAR_RELATIVE_LOCATIONS.find(
    (relativeLocation) => relativeLocation === location.pathname
  )

  return (
    <WebviewBridgeProvider>
      <Box sx={{ minHeight: { md: '150px' } }}>
        <Box
          maxWidth='xl'
          sx={{
            paddingX: { xs: 0, md: 6 },
            width: { md: 'calc(100% - 185px)' },
            position: { md: shouldRelativePositionLayout ? 'relative' : 'fixed' },
            top: 0,
            zIndex: 5,
          }}
        >
          <HideByLocationWrapper hiddenLocations={TOP_NAVBAR_HIDDEN_LOCATIONS} smallScreenOnly>
            <TopNavBar shouldStickyNavbar={shouldRelativePositionLayout} />
          </HideByLocationWrapper>
          <Box sx={{ paddingX: { xs: 3, md: 0 } }}>{children}</Box>
        </Box>
      </Box>
    </WebviewBridgeProvider>
  )
}
