import { createContext } from 'react'
import { ContentGridService, IContentGridService } from '../services/content-grid.service'
import { DesignHuddleProxyService, IDesignHuddleProxyService } from '../services/design-huddle-proxy.service'
import { DesignHuddleService, IDesignHuddleService } from '../services/design-huddle.service'

export interface IServicesContext {
  contentGrid: IContentGridService
  designHuddleProxy: IDesignHuddleProxyService
  designHuddle: IDesignHuddleService
}

export const services = Object.freeze({
  contentGrid: ContentGridService,
  designHuddleProxy: DesignHuddleProxyService,
  designHuddle: DesignHuddleService,
})

export const ServicesContext = createContext<IServicesContext>(services)
