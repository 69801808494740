import { Box, Skeleton, Typography } from '@mui/material'
import { Slider, theme } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import { MouseEventHandler } from 'react'
import { CarouselImageType } from '../../../../shared/domain/models/discover-carousel'

const FIVE_SECONDS = 5000

type CarouselSectionType = {
  onClick?: MouseEventHandler<HTMLAnchorElement>
  carouselImages: CarouselImageType[]
  carouselImagesError: boolean
}

const CarouselImage = styled.img`
  width: 100%;
  height: 100%;
  ${() => theme.breakpoints.down('sm')} {
    object-fit: cover;
  }
`

export default function CarouselSection({
  onClick,
  carouselImages,
  carouselImagesError,
}: CarouselSectionType): JSX.Element {
  if (carouselImagesError) {
    return <></>
  }

  return (
    <Box sx={{ overflow: 'hidden' }} mt={1}>
      {!carouselImagesError && carouselImages.length > 0 ? (
        <Slider settings={{ autoplay: { delay: FIVE_SECONDS } }}>
          {carouselImages.map((image: CarouselImageType) => (
            <a
              onClick={onClick}
              href={image.redirectTo || '/'}
              key={`${image.src}-${image.order}`}
              style={{ pointerEvents: image.redirectTo ? 'auto' : 'none' }}
            >
              <Box
                sx={{
                  borderRadius: 8,
                  width: '100%',
                  height: { xs: '160px', md: '220px', lg: '350px' },
                }}
              >
                <CarouselImage src={image.src} alt={image.alt} />
              </Box>
            </a>
          ))}
        </Slider>
      ) : (
        <Skeleton variant='rectangular' width={'100%'} height={350} />
      )}
    </Box>
  )
}
