import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { AssetType } from '../domain/models/asset-type'
import { ServicesContext } from './services.context'
import { useScreenSize } from '@avenue-8/platform-style-util-frontend'

type AssetTypesContextType = {
  assetTypes: AssetType[]
  assetTypesError: boolean
  assetTypesLoading: boolean
}

export const AssetTypesContext = createContext<AssetTypesContextType>({
  assetTypes: [],
  assetTypesError: false,
  assetTypesLoading: false,
})

export const AssetTypesProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [assetTypes, setAssetTypes] = useState<AssetType[]>([])
  const [assetTypesError, setAssetTypesError] = useState<boolean>(false)
  const [assetTypesLoading, setAssetTypesLoading] = useState<boolean>(false)
  const { contentGrid } = useContext(ServicesContext)

  // TODO: Verify bug, first both return false, then they return correct.
  const { isMobile, isDesktop } = useScreenSize()

  const filterAssetVisibility = ({ visibility }: AssetType, isDesktop: boolean, isMobile: boolean) =>
    !visibility ||
    visibility === 'both' ||
    (visibility === 'desktop' && isDesktop) ||
    (visibility === 'mobile' && isMobile)

  useEffect(() => {
    // TODO: Verify bug, first both return false, then they return correct.
    if (isMobile || isDesktop) {
      setAssetTypesLoading(true)
      contentGrid
        .getAssetTypes()
        .then((types) => {
          setAssetTypes(types.filter((type) => filterAssetVisibility(type, isDesktop, isMobile)))
        })
        .catch((_) => {
          setAssetTypesError(true)
        })
        .finally(() => {
          setAssetTypesLoading(false)
        })
    }
  }, [contentGrid, isDesktop, isMobile])

  return (
    <AssetTypesContext.Provider value={{ assetTypes, assetTypesError, assetTypesLoading }}>
      {children}
    </AssetTypesContext.Provider>
  )
}
