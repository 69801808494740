import { useFeatureFlags } from '@avenue-8/platform-shared-util-frontend'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { HomeTabs, TabItemDto } from './home-tabs'

export const HistoryHomeTabs = (): JSX.Element => {
  const history = useHistory()
  const { getFeatureData } = useFeatureFlags()
  const [tabs, setTabs] = useState<TabItemDto[]>([])
  const [activeTab, setActiveTab] = useState<TabItemDto | null>(null)

  const handleTabChange = (tab: TabItemDto) => {
    history.push(tab.value)
  }

  useEffect(() => {
    getFeatureData('pam2-landing-tabs').then((data) => {
      if (!data) {
        if (tabs.length) {
          setTabs([])
        }
        return
      }
      if (!Array.isArray(data)) {
        throw new Error('invalid landing tabs feature flag')
      }
      setTabs(data as TabItemDto[])
    })
  }, [tabs.length, getFeatureData])

  useEffect(() => {
    const matchingTab =
      tabs.find((tab) => {
        const tabHRef = history.createHref({ pathname: tab.value })
        const { pathname } = window.location
        return tabHRef === pathname
      }) ?? null
    setActiveTab(matchingTab)
  }, [history, tabs, activeTab])

  return <HomeTabs tabs={tabs} activeTab={activeTab} onChange={handleTabChange} />
}
