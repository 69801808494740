import { useFeatureFlags } from '@avenue-8/platform-shared-util-frontend'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { DHTemplateType } from '../domain/models/design-huddle'
import { ServicesContext } from './services.context'

type DHContextType = {
  readyToGoContent: DHTemplateType[]
  DHToken: string
  RTGLoading: boolean
  RTGId: number
}

const READY_TO_GO_CONTENT_CATEGORY_NAME_IN_FLAGSMITH = 'ready to go content'

export const DHContext = createContext<DHContextType>({
  readyToGoContent: [],
  DHToken: '',
  RTGLoading: true,
  RTGId: null,
})

export const DHContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [RTGLoading, setRTGLoading] = useState<boolean>(true)
  const [readyToGoContent, setReadyToGoContent] = useState<DHTemplateType[]>([])
  const [DHToken] = useState('') // TODO: this should be removed as its unused
  const [RTGId, setRTGId] = useState<number | null>(null)
  const { designHuddleProxy } = useContext(ServicesContext)
  const { getFeatureData } = useFeatureFlags()

  useEffect(() => {
    getFeatureData('dh-categories')
      .then((categories) => {
        if (!Array.isArray(categories)) {
          throw new Error('Invalid array of categories')
        }

        const RTGCategory = categories.find(
          (category) => category.category?.toLowerCase() === READY_TO_GO_CONTENT_CATEGORY_NAME_IN_FLAGSMITH
        )

        if (RTGCategory && RTGCategory.id !== RTGId) {
          setRTGId(RTGCategory.id)
          return designHuddleProxy
            .getReadyToGoContent(RTGCategory.id, 'user')
            .then((readyToGoContent: DHTemplateType[]) => {
              setReadyToGoContent(readyToGoContent)
            })
        }
      })
      .finally(() => {
        setRTGLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFeatureData])

  return <DHContext.Provider value={{ readyToGoContent, DHToken, RTGLoading, RTGId }}>{children}</DHContext.Provider>
}
