import { useAuthContext } from '@avenue-8/platform-shared-util-frontend'
import { useEffect } from 'react'

export const CheckAuth = (): JSX.Element => {
  const { authSBL } = useAuthContext()

  useEffect(() => {
    const handler = async (credentials) => {
      if (!credentials) {
        authSBL.requestAuthentication(true).catch((error) => {
          console.error('Failed to request authentication', error)
        })
      }
    }
    authSBL.addOnAuthChangeListener(handler)
    return () => {
      authSBL.removeOnAuthChangeListener(handler)
    }
  }, [authSBL])
  return <></>
}
