import { initDatadogRum } from '@avenue-8/platform-shared-util-frontend'
import { PamModule } from './modules/pam/pam.module'
import packageJson from '../package.json'

export type AppProps = {
  name: string
  singleSpa: any
}

initDatadogRum({ service: packageJson.name, version: '' })

export default function Root(props: AppProps): JSX.Element {
  return <PamModule />
}
