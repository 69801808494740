import { Button, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { ErrorBoundary } from '@avenue-8/platform-shared-util-frontend'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`

const ErrorBoundaryFallback = ({ error, resetErrorBoundary }: { error: any; resetErrorBoundary: () => void }) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(error)
  }
  return (
    <Container>
      <Typography variant='caption'>Something went wrong.</Typography>
      <Button onClick={() => resetErrorBoundary()} variant='outlined' size='small'>
        Refresh
      </Button>
    </Container>
  )
}

export const ErrorBoundaryProvider: React.FC = (props) => {
  return <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} {...props} />
}
